import axios from 'axios';

export default {
  getTransformerWindingsWithPagination(pageNumber, pageSize, nameFilter, transformerId, secondEquipmentId, sortField, sortDesc) {
    return axios.get('/api/TransformerWindings', {
      params: {
        pageNumber, 
        pageSize, 
        nameFilter, 
        transformerId: transformerId.length > 0 ? transformerId.join('!;!') : '',
        secondEquipmentId: secondEquipmentId.length > 0 ? secondEquipmentId.join('!;!') : '',
        sortField, 
        sortDesc
      }
    });
  },
};

