<template>
  <div>
    <transformer-windings-filter @active-change="filterActive = $event" :filter-model="filterModel" />
    <transformer-windings-table :filter-active="filterActive" :filter-model="filterModel" />
  </div>
</template>

<script>
import TransformerWindingsFilter from '@/views/Nsi/TransformerWindings/TransformerWindingsFilter';
import TransformerWindingsTable from '@/views/Nsi/TransformerWindings/TransformerWindingsTable';

export default {
  name: 'TransformerWindings',
  components: { TransformerWindingsTable, TransformerWindingsFilter },
  data() {
    return {
      filterModel: {
        nameFilter: '',        
        transformerId: '',
        secondEquipmentId: '',
      },
      filterActive: true
    };
  }
};
</script>

<style scoped>

</style>
